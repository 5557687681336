<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="角色名称">
                <el-input v-model="search.name" size="small"/>
            </x-search-item>

            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>
        <!-- 批量按钮区 -->
        <div class="x-page-btn">
            <el-button type="success" size="mini" round @click="handleAdd">新增</el-button>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border>
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column show-overflow-tooltip width="250" prop="name" label="角色名称" fixed/>
            <el-table-column show-overflow-tooltip prop="description" label="描述"/>
            <el-table-column width="120" label="状态">
                <x-dict-show slot-scope="{row}" :code="row.status" dictType="T_EFFECTIVE"/>
            </el-table-column>
            <el-table-column width="180" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-button type="primary" size="mini" round @click="handleUpdate(row.id)">编辑</el-button>
                    <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDelete(row.id)">
                        <el-button type="danger" size="mini" round slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
    </div>
</template>
<script>
    import * as service from "@/service/system/role";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/system/role/RoleEdit";

    export default {
        name: "Role",
        mixins: [XTableBase],
        components: {Edit},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                search: {
                    name: '',
                },
                editor: {
                    visible: false,
                    id: null,
                    isUpdate: false,
                }
            }
        }
    }
</script>

<style scoped>
</style>
