import request from '@/util/request'

//首页审核列表
export function auditMainList() {
    return request({
        url: '/audit/main/list',
        method: 'post'
    })
}

//更多审核列表
export function list(data) {
    return request({
        url: '/audit/list',
        method: 'post',
        data
    })
}

//获取所有审核待办项
export function auditAll() {
    return request({
        url: '/audit/audit-all',
        method: 'get'
    })
}