import request from '@/util/request'

//首页提醒列表
export function remindMainList() {
    return request({
        url: '/remind/main/list',
        method: 'post'
    })
}

//提醒列表
export function remindList(data) {
    return request({
        url: '/remind/list',
        method: 'post',
        data
    })
}

//获取所有提醒代办项
export function remindAll() {
    return request({
        url: '/remind/remind-all',
        method: 'get'
    })
}