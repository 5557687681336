<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open" center append-to-body
               width="1000" :close-on-click-modal="false" destroy-on-close>
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <x-divider title="角色信息"></x-divider>
            <el-form-item label="角色名称" prop="name">
                <el-input none v-model="form.name" size="small" maxlength="20" show-word-limit/>
            </el-form-item>
            <el-form-item label="状态" prop="status">
                <x-radio-group v-model="form.status" size="small" dictType="T_EFFECTIVE"/>
            </el-form-item>
            <el-form-item label="排序" prop="sort">
                <el-input-number v-model.number="form.sort" size="small" :min=0 :max=1000 :step=1></el-input-number>
            </el-form-item>
            <el-form-item label="描述" prop="description" class="line">
                <el-input none v-model="form.description" size="small" maxlength="20" show-word-limit/>
            </el-form-item>
            <x-divider>
                <el-tag size="small" style="cursor: pointer" @click="menuShow = !menuShow">菜单权限</el-tag>
            </x-divider>
            <div style="overflow-y: auto" :style="{height: menuShow ? '350px' : '0px'}">
                <el-input placeholder="输入关键字进行过滤" size="small" v-model="menuTree.filterText"></el-input>
                <el-tree ref="tree"
                         :data="menuTree.treeData"
                         :props="{children: 'subNodes', label: 'name'}"
                         node-key="id"
                         :default-expanded-keys="menuTree.expanded"
                         :filter-node-method="filterNode"
                         show-checkbox
                         highlight-current style="height:300px;">
                    <span class="custom-tree-node" slot-scope="{ node, data }">
                        <span class="data.icon">
                            {{data.name}}({{data.subNodes ? data.subNodes.length : 0}})
                        </span>
                        <span v-if="node.isLeaf">
                            <x-radio dict-type="T_DATA_AUTHORITY" v-model="data.dataAuthority"></x-radio>
                        </span>
                    </span>
                </el-tree>
            </div>
            <x-divider>
                <el-tag size="small" style="cursor: pointer" @click="auditShow = !auditShow">审核代办</el-tag>
            </x-divider>
            <div style="overflow-y: auto" :style="{height: auditShow ? '350px' : '0px'}">
                <el-input placeholder="输入关键字进行过滤" size="small" v-model="auditTree.filterText" style="width: 100%"></el-input>
                <el-tree ref="auditTree"
                         :data="auditTree.treeData"
                         :props="{children: 'subNodes', label: 'modular'}"
                         node-key="className"
                         :default-expanded-keys="auditTree.expanded"
                         :filter-node-method="filterNode1"
                         show-checkbox
                         highlight-current style="height:300px;">
                <span class="custom-tree-node" slot-scope="{ node, data }">
                    <span>{{data.modular}}</span>
                    <span>
                        <x-radio dict-type="T_DATA_AUTHORITY" v-model="data.dataAuthority"></x-radio>
                    </span>
                </span>
                </el-tree>
            </div>
            <x-divider>
                <el-tag size="small" style="cursor: pointer" @click="remindShow = !remindShow">提醒代办</el-tag>
            </x-divider>
            <div style="overflow-y: auto" :style="{height: remindShow ? '350px' : '0px'}">
                <el-input placeholder="输入关键字进行过滤" size="small" v-model="remindTree.filterText"></el-input>
                <el-tree ref="remindTree"
                         :data="remindTree.treeData"
                         :props="{children: 'subNodes', label: 'modular'}"
                         node-key="className"
                         :default-expanded-keys="remindTree.expanded"
                         :filter-node-method="filterNode1"
                         show-checkbox
                         highlight-current style="height:300px;">
                <span class="custom-tree-node" slot-scope="{ node, data }">
                    <span>{{data.modular}}</span>
                    <span>
                        <x-radio dict-type="T_DATA_AUTHORITY" v-model="data.dataAuthority"></x-radio>
                    </span>
                </span>
                </el-tree>
            </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add" :loading="loading">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, getUpdate, update} from "@/service/system/role";
    import {menuTreeForRoleEdit} from "@/service/system/menu";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';
    import {deepCopy, deepMerge} from '@/util/objects';
    import {auditAll} from '@/service/system/audit';
    import {remindAll} from '@/service/system/remind';

    export default {
        name: "RoleEdit",
        mixins: [XEditBase],
        data() {
            return {
                menuShow: true,
                auditShow: true,
                remindShow: true,
                menuTree: {
                    filterText: '',
                    expanded: [],
                    treeData: []
                },
                auditTree: {
                    filterText: '',
                    expanded: [],
                    treeData: []
                },
                remindTree: {
                    filterText: '',
                    expanded: [],
                    treeData: []
                }
            }
        },
        beforeCreate() {
            let {required} = ruleBuilder;
            this.rules = {
                name: [required()] // 角色名称
            }
            this.titlePrefix = "角色信息";
            this.defaultForm = {
                id: null,
                name: "", // 角色名
                status: "true", //状态
                sort: 0,    //排序
                classification: "0", //数据密级
                description: "", // 描述
                roleMenus: [],  //角色菜单关联
                roleAudits: [], //角色审核关联
                roleReminds: [] //角色提醒关联
            };
            menuTreeForRoleEdit().then(({data}) => this.defaultMenuTreeData = data);
            auditAll().then(({data}) => this.defaultAuditTreeData = data);
            remindAll().then(({data}) => this.defaultRemindTreeData = data);
        },
        watch: {
            "menuTree.filterText": function (val) {
                this.$refs.tree.filter(val);
            },
            "auditTree.filterText": function (val) {
                this.$refs.auditTree.filter(val);
            },
            "remindTree.filterText": function (val) {
                this.$refs.remindTree.filter(val);
            }
        },
        methods: {
            filterNode(value, data) {
                if (!value) return true;
                return data.name.indexOf(value) !== -1;
            },
            filterNode1(value, data) {
                if (!value) return true;
                return data.modular.indexOf(value) !== -1;
            },
            async save() {
                this.getCheckedAudit();
                this.getCheckedMenu();
                this.getCheckedRemind();
                await this.$refs.form.validate();
                let api = this.isUpdate ? update : add;
                const response = await api(this.form);//执行保存方法
                //打印提示信息
                this.$message.success(response.msg);
                this.$emit("submitted", response);
                this.cancel();
            },
            open() {
                this.menuTree.treeData = deepCopy(this.defaultMenuTreeData);
                this.auditTree.treeData = deepCopy(this.defaultAuditTreeData);
                this.remindTree.treeData = deepCopy(this.defaultRemindTreeData);

                let initTree = (data) => {
                    let roleMenus = data.roleMenus;
                    this.initializeTreeChecked(roleMenus, 'menuId', this.$refs.tree);

                    let roleAudits = data.roleAudits;
                    this.initializeTreeChecked(roleAudits, 'auditName', this.$refs.auditTree);

                    let roleReminds = data.roleReminds;
                    this.initializeTreeChecked(roleReminds, 'remindName', this.$refs.remindTree);
                }

                if (this.isUpdate) {
                    getUpdate(this.editor.id).then((response) => {
                        this.form = deepMerge(this.defaultForm, response.data);
                        //清空校验
                        this._clearValidate();
                        initTree(response.data);
                    })
                } else {
                    this.form = deepCopy(this.defaultForm);
                    //清空校验
                    this._clearValidate();
                    initTree();
                }
            },
            initializeTreeChecked(arrOfDataWithAuthority, nodeIdKey, treeInstance) {
                if (arrOfDataWithAuthority && arrOfDataWithAuthority.length) {
                    arrOfDataWithAuthority.forEach((obj) => {
                        let node = treeInstance.getNode(obj[nodeIdKey]);
                        if (node) {
                            node.data.dataAuthority = obj.dataAuthority;
                            if (node.isLeaf) {
                                treeInstance.setChecked(node, true);
                            }
                        }
                    });
                }
            },
            getCheckedMenu() {
                let menus = this.$refs.tree.getCheckedNodes();
                if (menus && menus.length) {
                    this.form.roleMenus = menus.concat(this.$refs.tree.getHalfCheckedNodes())
                        .map(menu => ({roleId: "", menuId: menu.id, dataAuthority: menu.dataAuthority}));
                } else {
                    this.form.roleMenus = [];
                }
            },
            getCheckedAudit() {
                let audits = this.$refs.auditTree.getCheckedNodes();
                if (audits && audits.length) {
                    this.form.roleAudits = audits.map(audit => ({
                        roleId: "",
                        auditName: audit.className,
                        dataAuthority: audit.dataAuthority
                    }));
                } else {
                    this.form.roleAudits = [];
                }
            },
            getCheckedRemind() {
                let reminds = this.$refs.remindTree.getCheckedNodes();
                if (reminds && reminds.length) {
                    this.form.roleReminds = reminds.map(remind => ({
                        roleId: "",
                        remindName: remind.className,
                        dataAuthority: remind.dataAuthority
                    }));
                } else {
                    this.form.roleReminds = [];
                }
            }
        }
    }
</script>

<style scoped>
    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
    }
</style>
